import React from "react"

import styles from "../../css/table-service.module.css"

/**
 * Tile properties.
 */
interface TileProps {
	key: string,
	x: number,
	y: number
}

/**
 * Tile component representing a tile contained within a row.
 */
export class Tile extends React.Component<TileProps> {

	constructor(props: TileProps) {
		super(props)

	}

	render = (): JSX.Element => {
		return (
			<td className={styles.tile}>({this.props.x}, {this.props.y})</td>
		)
	}
}
