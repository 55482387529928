import React from "react"

import Layout from "../components/center-layout"
import SEO from "../components/seo"

import { TileGrid } from "../components/table-service/tile-grid"


/**
 * Page component.
 */
export default class TableServicePage extends React.Component {

  /* 
   * Handles number input & state management.
   */
  handleInput = (event: any) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  /**
   * Renders the component.
   */
  render(): JSX.Element {
    return (
      <Layout>
        <SEO title="Table-Service" />
        <h1>Table-Service: An experiment designed to make table service easier.</h1>
        <TileGrid key="grid" x="5" y="5" />
      </Layout>
    )
  }
}
