import React from "react"

import { Tile } from "./tile"
import { TileRow } from "./tile-row"

import styles from "../../css/table-service.module.css"

/**
 * Tile grid properties.
 */
interface TileGridProps {
  x: string,
  y: string
}

/**
 * Grid component representing a grid filled with rows filled with tiles.
 */
export class TileGrid extends React.Component<TileGridProps> {

  tiles = undefined
  constructor(props: TileGridProps) {
    super(props)
    this.tiles = this.initializeTiles(props.x, props.y)
    console.log(this.tiles);

  }

  // Initializes a 2D array of tiles
  initializeTiles = (x: string, y: string) => {
    console.log(`x is ${x} and y is ${y}`);

    // Initialize tile grid structure
    let tiles = new Array(parseInt(x))
    for (let i = 0; i < parseInt(x); i++) {
      tiles[i] = new Array(parseInt(y))
    }

    // Initialize Tiles
    for (let i = 0; i < parseInt(x); i++) {
      for (let j = 0; j < parseInt(y); j++) {
        let key = `${i}-${j}`
        tiles[i][j] = <Tile key={key} x={i} y={j} />
      }
    }
    return tiles
  }

  renderTiles = (tiles: Tile[][]) => {

    // return tiles.map((tile) => {
    //   return tile
    // })

    let table = []

    // Outer loop to create parent
    for (let i = 0; i < tiles.length; i++) {
      let children = []
      //Inner loop to create children
      for (let j = 0; j < tiles[i].length; j++) {
        children.push(tiles[i][j])
      }

      //Create the parent and add the children
      // Use a TileRow component to stop React from complaining about a unique key prop
      table.push(<TileRow key={`row-${i}`} tiles={children} />)
    }
    return table

  }

  render() {
    return (
      <table className={styles.tileGrid}>
        {this.renderTiles(this.tiles)}
      </table>
    )
  }

}
