import React from "react"

import { Tile } from "./tile"

/**
 * Tile row properties.
 */
interface TileRowProps {
  key: string
  tiles: Tile[]
}

/**
 * Row component representing a row of tiles contained in a grid.
 */
export class TileRow extends React.Component<TileRowProps> {

  render = () => {
    return (
      <tbody><tr>{this.props.tiles}</tr></tbody>
    )
  }
}
